import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Hydro from "/src/components/img/hydro"
import ImgCon from "/src/components/img-con"
import SEO from "/src/components/seo"
import BtnWrap from "/src/components/btn-wrap"

import Hydroo1 from "/src/components/img/hydroo/1"
import Hydroo2 from "/src/components/img/hydroo/2"


export default () => (
  <Layout>
    <SEO title="Vodní nádrže" />
    <h1>Vodní nádrže</h1>
    <p><strong>Limnologie</strong> (t&eacute;ž hydrologie stojat&yacute;ch vod) je č&aacute;st hydrologie pevnin, kter&aacute; se zab&yacute;v&aacute; hydrologick&yacute;m režimem vodn&iacute;ch n&aacute;drž&iacute;.</p>
    <p><strong>Vodn&iacute; n&aacute;drž</strong> je přirozen&yacute;, nebo uměle vytvořen&yacute; prostor vyplněn&yacute; vodou. Přirozenou n&aacute;drž&iacute; jsou <strong>jezera</strong>, kdežto uměl&yacute;ch vodn&iacute;ch n&aacute;drž&iacute;, vytvořen&yacute;ch člověkem, je několik druhů a slouž&iacute; různ&yacute;m &uacute;čelům.</p>
    <p>N&aacute;drže <strong>zhlazuj&iacute;</strong><strong>hydrogram při povodni</strong>. Doch&aacute;z&iacute; ke sn&iacute;žen&iacute; vrcholu povodňov&eacute; vlny a jej&iacute;mu zplo&scaron;těn&iacute; a posouv&aacute;n&iacute; kulminace v&nbsp;čase, tento transformačn&iacute; efekt pak vyjadřuje <strong>Čerka&scaron;inova rovnice</strong>. Pro př&iacute;pad povodn&iacute; či sucha se často prov&aacute;d&iacute; manipulace s&nbsp;objemem vody v&nbsp;n&aacute;drži.</p>
    <hr /><h2>Klasifikace jezer</h2>
    <p>Jezera lze klasifikovat hned několika způsoby. Mezi z&aacute;kladn&iacute; patř&iacute; dělen&iacute; podle:</p>
    <ul>
    <li><strong>způsobu vzniku jezern&iacute; p&aacute;nve</strong>,</li>
    <li><strong>sil stoj&iacute;c&iacute;mi za vznikem jezera</strong>,</li>
    <li><strong>hydrologick&eacute;ho režimu n&aacute;drže</strong>,</li>
    <li><strong>chemick&eacute;ho složen&iacute; vody</strong>,</li>
    <li><strong>rozsahu prom&iacute;ch&aacute;v&aacute;n&iacute; vody v jezeru</strong></li>
    <li>a<strong> dle rozd&iacute;ln&eacute; teplotn&iacute; stratifikace</strong>.</li>
    </ul>
    <h3>Způsob vzniku jezern&iacute; p&aacute;nve</h3>
    <p>Jezera lze rozdělit <strong>podle způsobu vzniku jezern&iacute; p&aacute;nve</strong>, tedy sn&iacute;ženiny tvoř&iacute;c&iacute; n&aacute;drž. Způsoby jsou n&aacute;sleduj&iacute;c&iacute;:</p>
    <ul>
    <li><strong>hrazen&aacute;</strong> (vznikla zahrazen&iacute;m &uacute;dol&iacute; toku sesuvem, n&aacute;nosem, mor&eacute;nou, l&aacute;vov&yacute;m proudem apod.; zvl&aacute;&scaron;tn&iacute;m př&iacute;padem jsou jezera hrazen&aacute; vlastn&iacute;m chemick&yacute;m sedimentem &ndash; travertinem),</li>
    <li><strong>kotlinov&aacute; </strong>(tj. vyhlouben&aacute;; vznikla vyplněn&iacute;m existuj&iacute;c&iacute; sn&iacute;ženiny vodou)</li>
    <li><strong>sm&iacute;&scaron;en&aacute;</strong> (vznikla spolupůsoben&iacute;m v&iacute;ce činitelů či bylo v&yacute;razně přeformov&aacute;no jin&yacute;m činitelem; př&iacute;kladem jsou Velk&aacute; jezera, kter&aacute; vznikla tektonicky a byla přemodelov&aacute;na ledovcem, jin&yacute;m př&iacute;kladem může b&yacute;t kotlinov&eacute; jezero, kter&eacute; bylo v&nbsp;půlce přehrazen&eacute; sesuvem).</li>
    </ul>
    <h3>S&iacute;ly stoj&iacute;c&iacute; za utvořen&iacute;m jezera</h3>
    <p>Nebo je lze rozdělit dle <strong>sil, kter&eacute; jezero utvořily</strong>:</p>
    <ul>
    <li><strong>ledovcov&aacute;</strong> (vyznačuj&iacute; se značnou hloubkou, nez&aacute;vislou na v&yacute;&scaron;ce dna od hladiny moře; velmi četn&aacute;, různ&eacute; druhy &ndash; karov&aacute;, mor&eacute;nov&aacute; atd.; vysok&aacute; čirost, m&aacute;lo živin)</li>
    <li><strong>erozn&iacute;</strong> (<u>&uacute;doln&iacute; jezera</u>, later&aacute;ln&iacute; migrace překl&aacute;d&aacute; koryto, meandruje tok a vytv&aacute;ř&iacute; syst&eacute;m mrtv&yacute;ch a slep&yacute;ch ramen &ndash; archivace množstv&iacute; dat pro v&yacute;zkum; speci&aacute;ln&iacute;m př&iacute;kladem jsou <u>krasov&aacute; jezera</u> vznikl&aacute; chemickou eroz&iacute;)</li>
    <li><strong>tektonick&aacute; </strong>(obvykle rozs&aacute;hl&aacute;, prot&aacute;hl&aacute; a velmi hlubok&aacute;, lež&iacute; v&nbsp;synklin&aacute;l&aacute;ch či průkopov&yacute;ch propadlin&aacute;ch; Mrtv&eacute; moře, Tanganika, Bajkal atd.)</li>
    <li><strong>vulkanick&aacute;</strong> (často <u>kalderov&aacute; jezera</u> v&nbsp;kaldeře vulk&aacute;nu, popř&iacute;padě jezera vznikl&aacute; <u>zahrazen&iacute;m &uacute;dol&iacute; l&aacute;vov&yacute;m proudem</u>; nemus&iacute; b&yacute;t chemicky bezpečn&aacute;, v&nbsp;okol&iacute; často excentrick&aacute; radi&aacute;ln&iacute; ř&iacute;čn&iacute; s&iacute;ť)</li>
    <li><strong>pobřežn&iacute; jezera </strong>(tj. limany; vět&scaron;inou oddělen&iacute;m z&aacute;livů p&iacute;sčito-hlinit&yacute;mi n&aacute;nosy, či ve sn&iacute;ženin&aacute;ch po ustoupen&iacute; moře; typick&yacute;mi tvary b&yacute;vaj&iacute; duny)</li>
    <li><strong>eolick&aacute;</strong> (v aridn&iacute;ch oblastech, v&iacute;tr vyv&aacute;l mělk&eacute; sn&iacute;ženiny, kter&eacute; b&yacute;vaj&iacute; vyplněny vodou po de&scaron;t&iacute;ch či t&aacute;n&iacute; sněhu)</li>
    <li>a dal&scaron;&iacute; (např&iacute;klad organogenn&iacute;, meteoritick&aacute;)</li>
    </ul>
    <h3>Hydrologick&yacute; režim jezera</h3>
    <p>Dal&scaron;&iacute;m krit&eacute;riem pro rozdělen&iacute; jezer je jejich <strong>hydrologick&yacute; režim</strong>:</p>
    <ul>
    <li><strong>odtokov&aacute; jezero</strong> (voda z&nbsp;nich odt&eacute;k&aacute; v&nbsp;nejniž&scaron;&iacute;m m&iacute;stě hr&aacute;ze či do podzem&iacute;, popř&iacute;padě prosakuje, rozkyv hladin poměrně mal&yacute;, přev&aacute;žně sladkovodn&iacute;, reguluj&iacute; řeky &ndash; vyt&eacute;kaj&iacute; z&nbsp;jezera jako vyrovnan&eacute; a energeticky hodnotn&eacute;, působ&iacute; retenčně či jako akumulačn&iacute; n&aacute;drže)
    <ul>
    <li>jezera se <u>st&aacute;l&yacute;m</u>, ale i <u>občasn&yacute;m odtokem</u> &ndash; např. Čadsk&eacute; jezero</li>
    <li>dle toho, zda do jezera &uacute;st&iacute; povrchov&yacute; př&iacute;tok a m&aacute; zjevn&yacute; odtok: jezero <u>bez př&iacute;toku, s&nbsp;př&iacute;tokem, s&nbsp;př&iacute;tokem i odtokem</u></li>
    <li>pokud m&aacute; tok, kter&yacute; z&nbsp;jezera vyt&eacute;k&aacute;, stejn&yacute; stupeň jako tok přit&eacute;kaj&iacute;c&iacute; = <u>jezero průtočn&eacute;</u> &ndash; např. Čudsk&eacute; jezero</li>
    </ul>
    </li>
    <li><strong>bezodtokov&aacute; jezera </strong>(v&scaron;echen př&iacute;tok spotřebov&aacute;n na v&yacute;par z&nbsp;hladiny, průsak na dně d&iacute;ky miner&aacute;ln&iacute;m a organick&yacute;m usazenin&aacute;m minim&aacute;ln&iacute;; d&iacute;ky v&yacute;paru jsou slan&aacute;, a vysok&yacute;mi koncentracemi l&aacute;tek; vysok&aacute; rozkol&iacute;sanost hladiny, protože nen&iacute; regulov&aacute;na odtokem)
    <ul>
    <li>někdy bez vět&scaron;&iacute;ch př&iacute;toků, tedy jsou <u>uzavřen&aacute;, slep&aacute;</u></li>
    <li>pokud do nich &uacute;st&iacute; vět&scaron;&iacute; tok, mluv&iacute;me o jezeru <u>konečn&eacute;m</u> &ndash; např. Aralsk&eacute; jezero</li>
    </ul>
    </li>
    </ul>
    <h3>Chemick&eacute; složen&iacute; vody v jezeře</h3>
    <p>Vymezen&iacute; dle <strong>chemick&eacute;ho složen&iacute; vody</strong> pak zn&aacute; jezera:</p>
    <ul>
    <li><strong>sladkovodn&iacute; </strong>(tj. brakick&aacute;; s&nbsp;niž&scaron;&iacute;m obsahem miner&aacute;ln&iacute;ch l&aacute;tek),</li>
    <li><strong>soln&aacute; </strong>(tj. miner&aacute;ln&iacute;; s&nbsp;vysok&yacute;m obsahem miner&aacute;lů, v&nbsp;důsledku vysok&eacute;ho v&yacute;paru),</li>
    <li><strong>sm&iacute;&scaron;en&aacute;</strong> (č&aacute;st jezera miner&aacute;ln&iacute; a č&aacute;st brakick&aacute; vlivem neprom&iacute;ch&aacute;v&aacute;n&iacute; vody, např. Balcha&scaron;sk&eacute; jezero).</li>
    </ul>
    <h3>Rozsah prom&iacute;ch&aacute;v&aacute;n&iacute; vody v jezeru</h3>
    <p>Z&nbsp;hlediska <strong>rozsahu prom&iacute;ch&aacute;v&aacute;n&iacute; vody </strong>v&nbsp;jezeře, tj. dle v&yacute;měny vody a cirkulace v&nbsp;něm, rozezn&aacute;v&aacute;me:</p>
    <ul>
    <li><strong>holomiktn&iacute; jezera </strong>(&uacute;pln&eacute; prom&iacute;ch&aacute;v&aacute;n&iacute; vody v&nbsp;cel&eacute;m rozsahu jezern&iacute; p&aacute;nve),</li>
    <li><strong>meromiktn&iacute; jezera</strong> (omezen&eacute; prom&iacute;ch&aacute;v&aacute;n&iacute;, jen do určit&eacute; hloubky).</li>
    </ul>
    <h3>Rozd&iacute;ln&aacute; teplotn&iacute; stratifikace v jezeru</h3>
    <p>Jezera jde tak&eacute; dělit podle jejich <strong>teplotn&iacute; stratifikace</strong> (bl&iacute;že o t&eacute;to problematice v&nbsp;kapitole n&iacute;že) na:</p>
    <ul>
    <li><strong>tepl&aacute; </strong>(př&iacute;m&aacute; teplotn&iacute; stratifikace po cel&yacute; rok),</li>
    <li><strong>chladn&aacute; </strong>(př&iacute;m&aacute; teplotn&iacute; stratifikace v&nbsp;tepl&eacute; č&aacute;sti roku, nepř&iacute;m&eacute; ve studen&eacute;),</li>
    <li><strong>studen&aacute; </strong>(cel&yacute; rok nepř&iacute;m&aacute; teplotn&iacute; stratifikace).</li>
    </ul>
    <hr /><h2>Teplotn&iacute; stratifikace n&aacute;drže</h2>
    <p>Teplotn&iacute; stratifikac&iacute; rozum&iacute;me <strong>rozvrstven&iacute; teplot vody ve vodn&iacute; n&aacute;drži</strong> v&nbsp;z&aacute;vislosti na hloubce. Rozli&scaron;ujeme jej&iacute; tři z&aacute;kladn&iacute; typy.</p>
    <p>Prvn&iacute; je <strong>př&iacute;m&aacute; teplotn&iacute; stratifikace</strong>, ta nast&aacute;v&aacute;, pokud je při hladině teplota vody vy&scaron;&scaron;&iacute; než 4 &deg;C a s&nbsp;hloubkou tato teplota kles&aacute; na 4 &deg;C. <strong>Při t&eacute;to teplotě m&aacute; objemov&aacute; jednotka vody nejvy&scaron;&scaron;&iacute; hmotnost</strong>.</p>
    <p>Pokud je u hladiny teploty niž&scaron;&iacute; než 4 &deg;C, tak naopak teplota vody s&nbsp;hloubkou stoup&aacute; pr&aacute;vě k&nbsp;4 &deg;C. V&nbsp;takov&eacute;m př&iacute;padě hovoř&iacute;me o <strong>nepř&iacute;m&eacute; teplotn&iacute; stratifikaci</strong> (tj. obr&aacute;cen&eacute;).</p>
    <p>U chladn&yacute;ch jezer vlivem stř&iacute;d&aacute;n&iacute; ročn&iacute;ch obdob&iacute; doch&aacute;z&iacute; k&nbsp;vyrovn&aacute;n&iacute; teploty vody v&nbsp;cel&eacute; hloubce jezera na 4 &deg;C, tento stav naz&yacute;v&aacute;me <strong>homotermie</strong>.</p>
    <p>V&nbsp;obdob&iacute; s&nbsp;teplotn&iacute; stratifikac&iacute; pak můžeme vymezit tři z&aacute;kladn&iacute; vrstvy vody dle změny teploty s&nbsp;hloubkou:</p>
    <ul>
    <li><strong>epilimnion</strong> = vrchn&iacute; vrstva vody, nejintenzivněj&scaron;&iacute; cirkulace vody, změna teploty o 0,5 &deg;C na metr hloubky</li>
    <li><strong>metalimnion (skočn&aacute; vrstva)</strong> = v&yacute;razn&aacute; změna teploty s&nbsp;hloubkou, změna o 2 &deg;C na metr hloubky</li>
    <li><strong>hypolimnion = </strong>pomal&aacute; či ž&aacute;dn&aacute; cirkulace vody, minim&aacute;ln&iacute; změna teploty, změna 0,1 &deg;C na metr hloubky</li>
    </ul>
    <hr /><h2>Uměl&eacute; n&aacute;drže</h2>
    <p><strong>Uměl&eacute; n&aacute;drž</strong> je vodohospod&aacute;řsk&eacute; d&iacute;lo, vodn&iacute; n&aacute;drž vytvořen&aacute; člověkem. Mezi tyto n&aacute;drže patř&iacute; <strong>přehrady, rybn&iacute;ky a poldry</strong> (such&aacute;, či č&aacute;stečně prot&eacute;kan&aacute; n&aacute;drž slouž&iacute;c&iacute; k&nbsp;zachycen&iacute; v&yacute;razn&yacute;ch sr&aacute;žkov&yacute;ch ud&aacute;lost&iacute;). Pln&iacute; množstv&iacute; funkc&iacute; a přin&aacute;&scaron;&iacute; pozitivn&iacute; i negativn&iacute; efekty (efekt hladov&eacute; vody, eroze břehů, probl&eacute;m zan&aacute;&scaron;en&iacute; atd.).</p>
    <p>Dle <strong>Mezin&aacute;rodn&iacute; komise pro velk&eacute; přehrady &ndash; ICOLD</strong>, existuje v&nbsp;současnosti asi 35 tis&iacute;c <strong>velk&yacute;ch vodn&iacute;ch n&aacute;drž&iacute;</strong> s&nbsp;celkovou plochou kolem 600 tis&iacute;c km2. Mezi tyto patř&iacute; takov&eacute; n&aacute;drže, kter&eacute; splnily někter&eacute; z&nbsp;n&aacute;sleduj&iacute;c&iacute;ch krit&eacute;ri&iacute;:</p>
    <ul>
    <li>v&yacute;&scaron;ka přehrady nad 15 m,</li>
    <li>d&eacute;lka koruny hr&aacute;ze nad 500 m,</li>
    <li>maxim&aacute;ln&iacute; povodňov&yacute; průtok nad 2 000 m<sup>3</sup>.s<sup>-1</sup>,</li>
    <li>objem alespoň 1 mil. m<sup>3</sup>.</li>
    </ul>
    <p>Největ&scaron;&iacute;mi n&aacute;držemi z&nbsp;hlediska objemu vody jsou na světě v&nbsp;n&aacute;sleduj&iacute;c&iacute;m pořad&iacute;:</p>
    <ol>
    <li>Tři soutěsky (Jang c&rsquo;tiang, Č&iacute;na)</li>
    <li>Owen Falls (Viktoriin Nil, Uganda)</li>
    <li>Bratsk&aacute; (Angara, Rusko)</li>
    <li>Asu&aacute;nsk&aacute; (Nil, Egypt)</li>
    <li>Kariba (Zambezi, Zimbabwe)</li>
    </ol>
    <p>V&nbsp;ČR je dle objemu největ&scaron;&iacute; n&aacute;drž Orl&iacute;k (717 mil. m3), co do plochy Lipno I (4910 ha) a co do hloubky i v&yacute;&scaron;ky hr&aacute;ze Dale&scaron;ice (hloubka 86 m, hr&aacute;z 100 m)</p>
    <hr />
    <ImgCon><Hydro /><div>
    <h2>Studijn&iacute; materi&aacute;ly Hydrologie</h2>
    <p>T&eacute;ma <strong>Vodní nádrže</strong> je souč&aacute;st&iacute; <Link to="/hydrologie/">studijn&iacute;ch materi&aacute;lů Hydrologie a hydrogeografie</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předešlé (2.&nbsp;tlač&iacute;tko) či následující (3. tlačítko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/hydrologie/"><button>Rozcestník hydrologie</button></Link>
    <Link to="/hydrologie/prameny-mineralni-vody/"><button className="inv">&larr; Prameny a minerální vody</button></Link>
    <Link to="/hydrologie/mokrady/"><button className="inv">Mokřady &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />

  </Layout>
)
